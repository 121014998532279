import React from "react"
import { Helmet } from "react-helmet"

export default function About() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="Description" content="Lance – Blog"></meta>

        <title>Lance – Blog</title>
        <link rel="preload" href="/fonts/fonts.css" as="style"></link>
        <body className="scrollSmooth" />
      </Helmet>
      <>
        <div className=" font-light mx-auto mt-24 mb-48 text-3xl">
          <p className="mb-4 displayFont max-w-4xl">
            Lance helps businesses standout through branding & design. He has
            worked with different companies backed by YC, Amazon Alexa Fund and
            True Ventures to count a few. While he is also known as a
            hand-lettering artist and brand designer, all those things are only
            small pieces of the brand puzzle.
          </p>
          
        </div>

        <div className="mx-auto">
          <h1 className="mb-24 text-center ">What can I help you with?</h1>
          <div className="mb-16 grid md:grid-cols-12 grid-cols-1">
            <div className="col-span-4 max-w-sm mb-4 lg:mb-0">
              <h2>Scratch the surface.</h2>
            </div>
            <div className="col-span-2"></div>
            <div className="col-span-6 max-w-lg">
              <h3 className="medium">Discovery & Kick off</h3>
              <p className="text-base mb-2">
                It's important to know who you are at the moment, where do you
                want to go, and how you can get there. Conducting a check-up of
                your current experience, market, differentiators, goals and
                target audience ensures that the subsequent branding process is
                specific to your needs and those of your customers.
              </p>
            </div>
          </div>

          <div className="mb-16 grid md:grid-cols-12 grid-cols-1">
            <div className="col-span-4 max-w-sm mb-4 lg:mb-0">
              <h2>Plan it out.</h2>
            </div>
            <div className="col-span-2"></div>
            <div className="col-span-6 max-w-lg">
              <h3 className="medium">Brand Strategy</h3>
              <p className="text-base mb-2">
                Strategy is key when it comes to branding. Defining a strong 
                rationale for your brand, will help define the image you want to create in the minds of your current and potential customers. This ensures that all the subsequent processes are in line with the brand you defined.
              </p>
            </div>
          </div>

          <div className="mb-16 grid md:grid-cols-12 grid-cols-1">
            <div className="col-span-4 max-w-sm mb-4 lg:mb-0">
              <h2>Everything you need to put your best face forward.</h2>
            </div>
            <div className="col-span-2"></div>
            <div className="col-span-6 max-w-lg">
              <h3 className="medium">Visual Identity</h3>
              <p className="text-base mb-2">
                The basic design elements you need to present yourself in the
                best light. These are the main visual ingredients to support
                your brand identity.
              </p>
            </div>
          </div>
        </div>
      </>
    </>
  )
}
